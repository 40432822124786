






.spa-ui-layer-info-link {
  border-bottom: 1px dotted $info-link-border;

  &:focus,
  &:hover {
    border-bottom: 1px solid $info-link-focus-border;
    text-decoration: none;
  }
}

.spa-ui-layer {
  .spa-content-alpha-layer {
    display: none;
    position: absolute;
    bottom: 16px;
    left: 10px;
    right: 10px;
    height: 40px;
    pointer-events: none;

    background: -moz-linear-gradient(bottom,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%); 
    background: -webkit-linear-gradient(bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%); 
    background: linear-gradient(to top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); 
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
  }

  &-close {
    position: absolute;
    top: 12px;
    right: 15px;
    text-decoration: none;
    overflow: hidden;

    .spa-close-x {
      position: relative;
      color: $bank-brown-quinary;
      font-size: 40px;
      transition: $spa-standard-transition;
    }

    &:focus, &:hover {
      text-decoration: none !important;

      .spa-close-x {
        color: $bank-brown-tertiary;
        transition: $spa-standard-transition;
      }
    }
  }

  .spa-body {
    color: $bank-dark-gray;
    font: 14px / 18px $spa-font-family;

    h3 {
      font-size: 18px;
      line-height: 25px;
      color: $bank-brown-tertiary;
      padding-bottom: 5px;
      margin-top: 0;
      line-height: 28px;
    }
  }

  .content {
    overflow-y: auto;
    outline: none;
    position: relative;

    .spa-btn {
      overflow: hidden;

      &.close-interstitial {
        margin-right: 15px;
      }
    }
  }
}


body {
  &.spa-ui-freeze-scroll {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media #{$small-only} {
      .spa-layout-container {
        overflow: auto;
      }
    }

    .spa-layout-container {
      height: 100%;


      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        overflow: auto
      }
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2)  {
        overflow: auto
      }
    }
  }

  &.spa-ui-freeze-scroll.spa-ui-no-js-scroll {
    overflow: hidden;
    position: static;
  }
}
