





.spa-ui {
  &-modal {
    bottom: 0;
    height: 100%;
    left: 0;
    overflow-y: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transform-style: preserve-3d;
    width: 100%;

    &:before {
      background: rgba(82, 73, 64, 0.9);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1000;
    }

    .spa-ui-layer-close {
      .spa-close-x {
        color: $oil;

        &:hover {
          color: $black;
        }
      }
    }

    &-interstitial {
      .spa-header {
        background-color: $red-primary;
        background-size: cover;
        height: 50px;
        margin-bottom: 16px;

        &.edge {
          background-color: $blue-primary;
        }

        &.enterprise {
          background-color: $blue-primary;
        }

        &.co-brand {
          margin-bottom: 45px;

          &:after {
            bottom: -40px;
            color: $blue-primary;
            content: 'INTUIT';
            font-size: 2em;
            position: absolute;
            right: 30px;
          }
        }

        .spa-ui-layer-close {
          right: 12px;
          top: 12px;

          .spa-close-x {
            color: $white;

            &:hover {
              color: $brand-pale-gray-light;
            }
          }
        }
      }
    }

    .spa-dialog {
      left: 50% !important;
      margin-left: auto;
      max-height: 100%;
      max-width: 95%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      z-index: 1001;

      &.spa-ui-position-top {
        top: 0;
        transform: translate(-50%, 0%);
      }

      &.spa-ui-position-right {
        left: auto !important;
        right: 0;
        transform: translate(0%, -50%);
      }

      &.spa-ui-position-bottom {
        bottom: 0;
        top: auto;
        transform: translate(-50%, 0%);
      }

      &.spa-ui-position-left {
        left: 0 !important;
        transform: translate(0%, -50%);
      }
    }

    .spa-header {
      padding: 10px 20px;
      position: relative;
    }

    .spa-content {
      background: none repeat scroll 0 0 $modal-layer-background-color;
      color: #333;
      font: 14px / 18px $spa-font-family;
      max-height: 100vh;
      overflow-y: auto;

      @media #{$small-only} {
        margin: 10px;
      }
    }

    .spa-body {
      padding: 10px 25px 30px 25px;

      .spa-ui-layer-title {
        line-height: 25px;
        color: $bank-brown-tertiary;
        padding-bottom: 5px;
        margin-top: 0;
        line-height: 28px;
        font-size: 24px;
      }

      p {
        font-size: 14px;
        line-height: 1.3rem;
      }
    }

    .spa-content-alpha-layer {
      bottom: 28px;
    }
  }
}


.spa-ua-system-ios {
  .spa-ui-modal {
    .spa-dialog {
      top: 100px;
      position: fixed;
      -webkit-transform: translate(-50%, 0);
    }
  }
}

.spa-ua-system-iphone {
  .spa-ui-modal {
    .spa-dialog {
      top: 5px;
      width: 100% !important;
    }
  }
}
