





$ui-layer-background-color: $bank-white;

$ui-layer-box-shadow-color: #9a8c7f;
$ui-layer-border-color: #bcb2a4;
$tooltip-border-color: $bank-light-gray-tertiary;

$ui-layer-content-background-color: $bank-white;

$ui-layer-content-text-color: $bank-dark-gray;

$info-link-border: $link-color;

$info-link-focus-border: #00f;

$info-layer-close-x-border-color: #c0c0c0;

$info-layer-overlay-color: rgba(82, 73, 64, 0.90);
$info-layer-background-color: $ui-layer-background-color;

$info-layer-box-shadow-color: $ui-layer-box-shadow-color;
$info-layer-border-color: $tooltip-border-color;

$info-layer-content-background-color: $ui-layer-content-background-color;
$info-layer-content-text-color: $ui-layer-content-text-color;

$info-layer-header-background-gradient-start: #fefefe;
$info-layer-header-background-gradient-end: #e8eaed;

$info-layer-header-border-bottom-color: $bank-light-gray;

$info-layer-max-width: 400px;

$modal-layer-background-color: $ui-layer-background-color;

$modal-layer-header-border-bottom-color: #e0d9d3;

$task-layer-background-color: $ui-layer-background-color;

$task-layer-box-shadow-color: $ui-layer-box-shadow-color;
$task-layer-border-color: $ui-layer-border-color;

$task-layer-content-background-color: $ui-layer-content-background-color;
$task-layer-content-text-color: $ui-layer-content-text-color;

$task-layer-body-header-border-bottom-color: #e0d9d3;

$task-layer-arrow-after-border-color: $bank-white;
$task-layer-arrow-before-border-color: #bcb2a4;
