






@keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDownModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUpModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media #{$medium-up} {
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 25%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutUpModal {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDownModal {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media #{$medium-up} {
  @keyframes fadeOutUp {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
  }

  @keyframes fadeOutDown {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, 25%, 0);
    }
  }
}

[data-sparta-widget] {
  .spa-ui-info {
    min-width: 300px;
  }
}

[data-sparta-container] {

  a.spa-ui-layer-tooltip-link:not(.icon-link) {
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px dotted;

    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom-color: transparent;
    }
  }

  .spa-ui {
    &-info {
      position: fixed;
      height: auto;
      width: 100%;
      max-width: calc(100% - 20px); 

      overflow-y: auto; 
      z-index: 400;
      border: 1px solid $info-layer-border-color;
      border-radius: 12px;
      background-color: $white;
      line-height: 1;
      text-align: left;
      padding: 0;

      @media #{$small-up} {
        max-width: $info-layer-max-width;
      }

      animation-duration: 0.7s;
      animation-fill-mode: both;
      animation-iteration-count: 1;



      .spa-content {
        h2, h3 {
          font-size: 22px !important;
          line-height: 1.2 !important;
          font-weight: normal !important;
          color: $brown-tertiary !important;
          padding: 0 !important;
          margin: 0 0 5px 0 !important;
        }
        .spa-close-x {
          font-size: 40px !important;
          font-weight: 400 !important;
          line-height: 18px !important;
        }
        .content {
          p {
            font-size: 14px !important;
            line-height: 1.3 !important;
            color: $copy-gray-2 !important;
          }
          a {
            font-size: 14px !important;
            line-height: 1.3 !important;
            color: $brand-royal-blue !important;
            &:hover {
              color: $brand-dark-blue !important;
            }
          }
        }

              } 

      .spa-ui-layer-close {
        &,
        &:hover,
        &:focus {
          text-decoration: none !important;
          border: none !important;
        }

        .spa-close-x {
          color: $oil;
          &:hover {
            color: $black;     
          }
        }
      }

      &.full-width {
        left: 0 !important;
        width: 100% !important;
      }

      &.spa-ui-animate-fadeInDown {
        animation-name: fadeInDown;
      }

      &.spa-ui-animate-fadeOutUp {
        animation-name: fadeOutUp;
      }

      &.spa-ui-animate-fadeInUp {
        animation-name: fadeInUp;
      }

      &.spa-ui-animate-fadeOutDown {
        animation-name: fadeOutDown;
      }

      .spa-layer-content {
        height: auto;

        background: $info-layer-background-color;
        color: $info-layer-content-text-color;
        font: 14px/18px $spa-font-family;
        overflow-y: auto;
        overflow-x: hidden;

        @media #{$medium-up} {
          background: none;
          max-height: none;
        }
      }

      .spa-header {
          position: relative;
          top: auto;
          left: auto;
          width: auto;
          height: 20px;
          display: block !important;
      }

      .spa-body {
        padding: 34px 5px 20px 20px;
        margin-right: 15px;
        -webkit-overflow-scrolling: auto;

        @media #{$medium-up} {
          padding-top: 14px;
        }

        h3 {
          padding: 0;
          margin: 0 0 5px 0;
          line-height: 1.2;
          font-size: 22px;
          font-weight: normal;
        }

        p {
          font-size: 14px;
          line-height: 1.3;
        }

        .content {
          max-height: 100%;
          position: relative;

          @media #{$medium-up} {
            max-height: 400px;
          }

          > p {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
