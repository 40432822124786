@import '/apps/wt/webtools/sparta_build_server/tmp/2BD26A73/artifacts/components/utilities/global/sparta-style-utility/3.2.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;







        @import '_config.scss';

        @import '_general.scss';

        @import 'modals/_modals.scss';

        @import 'info-layers/_info-layers.scss';

        @import 'task-layers/_task-layers.scss';
